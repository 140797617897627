import React, { useState, useEffect, useRef } from 'react';
import { 
    Container, 
    Box, 
    Button, 
    CircularProgress, 
    Typography,
    Paper,
    TextField,
    ImageList,
    ImageListItem,
    Alert,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    LinearProgress,
    useMediaQuery,
    Menu,
    MenuItem,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
} from '@mui/material';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountCircle from '@mui/icons-material/AccountCircle';
import BuyCredits from './BuyCredits';
import Help from './Help';
import SplashPage from './SplashPage';
import ReactGA from 'react-ga4';
import ChangePassword from './ChangePassword';
import { v4 as uuidv4 } from 'uuid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import GetStartedOverlay from './GetStartedOverlay';
import axios from 'axios';

function App() {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    const getImageListCols = () => {
        if (isXs) return 1;
        if (isSm) return 2;
        if (isMd) return 3;
        return 4;
    };

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [previewUrls, setPreviewUrls] = useState([]);
    const [loading, setLoading] = useState(false);
    const [trainStatus, setTrainStatus] = useState(null);
    const [modelId, setModelId] = useState(null);
    const [prompt, setPrompt] = useState('');
    const [error, setError] = useState(null);
    const [trainingProgress, setTrainingProgress] = useState('');
    const [token, setToken] = useState(() => localStorage.getItem('token'));
    const [userEmail, setUserEmail] = useState(() => localStorage.getItem('userEmail'));
    const [showAuth, setShowAuth] = useState(false);
    const [isLogin, setIsLogin] = useState(true);
    const [authError, setAuthError] = useState('');
    const [authEmail, setAuthEmail] = useState('');
    const [authPassword, setAuthPassword] = useState('');
    const [userModels, setUserModels] = useState([]);
    const [selectedModelId, setSelectedModelId] = useState(null);
    const [generatedImages, setGeneratedImages] = useState([]);
    const [progress, setProgress] = useState(0);
    const [latestLogLine, setLatestLogLine] = useState('');
    const [overlayOpen, setOverlayOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageGeneratedNotification, setImageGeneratedNotification] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [modelTrainingCredits, setModelTrainingCredits] = useState(0);
    const [imageGenerationCredits, setImageGenerationCredits] = useState(0);
    const [creditError, setCreditError] = useState('');
    const [trainingCreditError, setTrainingCreditError] = useState('');
    const [imageLoading, setImageLoading] = useState(false);
    const [editingModelId, setEditingModelId] = useState(null);
    const [newCustomName, setNewCustomName] = useState('');
    const [showBuyCredits, setShowBuyCredits] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [loadingRewrite, setLoadingRewrite] = useState(false);
    const [imageLoadStates, setImageLoadStates] = useState({});
    const [deleteMode, setDeleteMode] = useState(false);
    const [imagesToDelete, setImagesToDelete] = useState([]);
    const [imagesMenuAnchorEl, setImagesMenuAnchorEl] = useState(null);
    const [samplePromptsOpen, setSamplePromptsOpen] = useState(false);
    const [visibleImagesCount, setVisibleImagesCount] = useState(40);
    const [isNewUser, setIsNewUser] = useState(false);
    const [trainingSuccessMessage, setTrainingSuccessMessage] = useState(false);
    const [dataFetched, setDataFetched] = useState(false);

    const pollingTimeoutRef = useRef(null);

    const samplePrompts = [
        'USER as a Renaissance noble at the royal court, dressed in elaborate 16th-century attire.',
        'USER as a medieval warrior wielding a huge axe',
        'USER on the cover of a 1960s album for a pop band',
        'USER in a French illustrated magazine ad for a fashion brand from the 1930s',
        'USER in a watercolor painting by Thomas Gainsborough of an elegant Victorian gentleman or lady, strolling through a garden with a cane',
        'USER on a mystical quest as a fantasy hero, wearing armor with a magical sword in an enchanted forest.',
        'USER as a cyberpunk hacker in a futuristic neon-lit cityscape, with cool tech accessories.',
        // Add more sample prompts as desired
    ];

    const handleSamplePromptsOpen = () => {
        setSamplePromptsOpen(true);
    };

    const handleSamplePromptsClose = () => {
        setSamplePromptsOpen(false);
    };

    const handleSelectSamplePrompt = (samplePrompt) => {
        setPrompt(samplePrompt);
        setSamplePromptsOpen(false);
    };

    useEffect(() => {
        if (token) {
            // Fetch data and set dataFetched to true once done
            Promise.all([
                fetchUserModels(),
                fetchGeneratedImages(),
                fetchUserCredits(),
                fetchTrainingStatus()
            ]).then(() => {
                setDataFetched(true);
            });
        }

        // Initialize Google Analytics
        ReactGA.initialize('G-MGCMG6QFCG');
        ReactGA.send('pageview');
    }, [token]);

        const fetchUserCredits = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user-credits`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user credits');
                }

                const data = await response.json();
            //console.log('Fetched credits:', data);
                setModelTrainingCredits(data.modelTrainingCredits);
                setImageGenerationCredits(data.imageGenerationCredits);
            } catch (error) {
                console.error('Error fetching user credits:', error);
            }
        };

    const fetchUserModels = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/models`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch models');
            }

            const data = await response.json();
            //console.log('Fetched models:', data.models);
            setUserModels(data.models);

            const succeededModels = data.models.filter(model => model.status === 'succeeded');
            if (succeededModels.length === 1) {
                setSelectedModelId(succeededModels[0].modelId);
            } else {
                setSelectedModelId(null);
            }
        } catch (error) {
            console.error('Error fetching models:', error);
            setError('Failed to load models');
        }
    };

    const fetchGeneratedImages = async () => {
        try {
            //console.log('Token:', token);
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/generated-images`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch generated images');
            }

            const data = await response.json();
            setGeneratedImages(data.images || []);
            setError(null);

            // Log the URLs of the fetched images
            //console.log('Fetched generated images:', data.images.map(image => image.url));
        } catch (error) {
            console.error('Error fetching generated images:', error);
            setError('Failed to load generated images');
        }
    };

    const updateModelId = (id) => {
        setModelId(id);
    };

    const updateTrainStatus = (status) => {
        setTrainStatus(status);
    };

    const handleFileSelect = (event) => {
        const files = Array.from(event.target.files);
        if (uploadedFiles.length + files.length > 20) {
            setError('Maximum 20 images allowed');
            return;
        }

        setUploadedFiles(prev => [...prev, ...files]);
        
        // Create preview URLs
        files.forEach(file => {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrls(prev => [...prev, reader.result]);
            };
            reader.readAsDataURL(file);
        });
    };

    const removeImage = (index) => {
        setUploadedFiles(prev => prev.filter((_, i) => i !== index));
        setPreviewUrls(prev => prev.filter((_, i) => i !== index));
    };

    const handleTrain = async () => {
        // First check if there are any in-progress trainings
        const inProgressModels = userModels.filter(model => 
            ['pending', 'starting', 'processing', 'training'].includes(model.status)
        );

        if (inProgressModels.length > 0) {
            setError('A training session is already in progress. Please wait until it completes.');
            return;
        }

        if (uploadedFiles.length < 1) {
            setError('Please upload at least one image');
            return;
        }

        if (modelTrainingCredits < 1) {
            setTrainingCreditError(
                <>
                    You do not have any model training credits.{' '}
                    <a href="#" onClick={handleBuyCredits} style={{ color: '#1976d2', textDecoration: 'underline' }}>
                        Buy Credits
                    </a>
                </>
            );
            return;
        }

        setLoading(true);
        updateTrainStatus('training');
        setError(null);
        setTrainingCreditError('');

        const formData = new FormData();
        uploadedFiles.forEach((file, index) => {
            formData.append('images', file);
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/train`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.error === 'You already have a training in progress. Please wait until it completes before starting a new one.') {
                    setError(errorData.error);
                    setLoading(false);
                    updateTrainStatus(null);
                    return;
                }
                if (errorData.error === 'No model training credits available') {
                    setTrainingCreditError(
                        <>
                            You do not have any model training credits.{' '}
                            <a href="#" onClick={handleBuyCredits} style={{ color: '#1976d2', textDecoration: 'underline' }}>
                                Buy Credits
                            </a>
                        </>
                    );
                } else {
                    throw new Error(errorData.error || 'Training failed to start');
                }
                setLoading(false);
                updateTrainStatus('failed');
                return;
            }

            const data = await response.json();
            updateModelId(data.modelId);
            updateTrainStatus('training');
            
            // Start polling immediately
            pollTrainingStatus(data.modelId);  // <-- Add this line
            
            // Optional: Start regular polling interval
            const pollInterval = setInterval(() => {
                pollTrainingStatus(data.modelId);
            }, ACTIVE_POLLING_INTERVAL);

            // Clean up interval when component unmounts
            return () => clearInterval(pollInterval);

        } catch (error) {
            console.error('Error:', error);
            setError('Failed to start training');
            updateTrainStatus('failed');
            setLoading(false);
        }
    };

    const ACTIVE_POLLING_INTERVAL = 1000; // 1 second for active progress monitoring
    const BACKGROUND_POLLING_INTERVAL = 30000; // 30 seconds for background checks

    useEffect(() => {
        let intervalId = null;
        
        // Only proceed if we have a token
        if (token) {
            // Filter models that are not in 'succeeded' or 'failed' state
            const inProgressModels = userModels.filter(model => 
                !['succeeded', 'failed'].includes(model.status)
            );

            if (inProgressModels.length > 0) {
                // Determine if we're actively monitoring any model's progress
                const isActivelyMonitoring = trainStatus === 'training' && modelId;
                
                // Set polling interval based on whether we're actively monitoring
                const pollingInterval = isActivelyMonitoring ? ACTIVE_POLLING_INTERVAL : BACKGROUND_POLLING_INTERVAL;

                intervalId = setInterval(() => {
                    inProgressModels.forEach(model => {
                        // Only do frequent polling for the model being actively monitored
                        if (isActivelyMonitoring && model.modelId === modelId) {
                            pollTrainingStatus(model.modelId);
                        } else if (!isActivelyMonitoring) {
                            // Background polling for other models
                            checkTrainingStatus(model.modelId);
                        }
                    });
                }, pollingInterval);
            }
        }

        // Cleanup function
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [userModels, trainStatus, modelId, token]); // Add all dependencies

    const pollTrainingStatus = async (modelId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/training-status/${modelId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            const data = await response.json();

            setLatestLogLine(data.latestLogLine || '');
            
            setTrainingProgress(`Status: ${data.status} | Progress: ${data.progress || 0}%`);
            setProgress(data.progress || 0);

            if (data.status === 'succeeded') {
                handleTrainingSuccess();
            } else if (data.status === 'failed') {
                handleTrainingFailure();
            } else if (data.status === 'training' || data.status === 'processing') {
                updateTrainStatus('training');
            }
        } catch (error) {
            console.error('Error polling training status:', error);
        }
    };

    const checkTrainingStatus = async (modelId) => {
        try {
            const response = await axios.get(`/api/training-status/${modelId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            
            // Just update the model status in state
            setUserModels(prevModels => prevModels.map(model => 
                model.modelId === modelId ? { ...model, status: response.data.status } : model
            ));
        } catch (error) {
            console.error(`Error checking status for model ${modelId}:`, error);
        }
    };

    const handleGenerate = async () => {
        if (!selectedModelId || !prompt) return;

        setImageLoading(true);
        setError(null);
        setCreditError('');

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/generate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    modelId: selectedModelId,
                    prompt: prompt,
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.error === 'No image generation credits available') {
                    setCreditError(
                        <>
                            You do not have any image generation credits.{' '}
                            <a href="#" onClick={handleBuyCredits} style={{ color: '#1976d2', textDecoration: 'underline' }}>
                                Buy Credits
                            </a>
                        </>
                    );
                } else {
                    throw new Error(errorData.error || 'Generation failed');
                }
                return;
            }

            const data = await response.json();
            if (data.image) {
                const newImage = data.image; // Use the image returned by the server

                setGeneratedImages(prevImages => [...prevImages, newImage]);
                setImageGeneratedNotification(true);

                // Add this line to fetch updated credits
                await fetchUserCredits(); // Fetch updated credits after image generation
            } else {
                setError('No output received from the server.');
            }
        } catch (error) {
            console.error('Error:', error);
            setError(error.message || 'Failed to generate image');
        } finally {
            setImageLoading(false);
        }
    };

    const handleUserAction = () => {
        setImageGeneratedNotification(false);
    };

    const handleReset = () => {
        setModelId(null);
        setTrainStatus(null);
        setUploadedFiles([]);
        setPreviewUrls([]);
        setPrompt('');
        setError(null);
        setTrainingProgress('');
    };

    const fetchTrainingStatus = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/training-status`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch training status');
            }

            const data = await response.json();

            const ongoingTrainings = data.trainings.filter(training =>
                ['pending', 'starting', 'processing'].includes(training.status)
            );

            if (ongoingTrainings.length > 0) {
                ongoingTrainings.forEach(training => {
                    setModelId(training.modelId);
                    updateTrainStatus('training');
                    pollTrainingStatus(training.modelId);  // <-- Add immediate polling
                });
            } else {
                updateTrainStatus(null);
            }
        } catch (error) {
            console.error('Error fetching training status:', error);
        }
    };

    const handleAuth = async (e) => {
        e.preventDefault();
        setAuthError('');
        
        // Clear any ongoing polling
        if (pollingTimeoutRef.current) {
            clearTimeout(pollingTimeoutRef.current);
            pollingTimeoutRef.current = null;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/auth/${isLogin ? 'login' : 'register'}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: authEmail,
                    password: authPassword,
                }),
            });

            const data = await response.json();
            
            if (!response.ok) {
                throw new Error(data.error || 'Authentication failed');
            }

            localStorage.setItem('token', data.token);
            localStorage.setItem('userEmail', data.email);
            setToken(data.token);
            setUserEmail(data.email);
            setUserModels(data.models || []);
            setShowAuth(false);
            setAuthEmail('');
            setAuthPassword('');
            setGeneratedImages([]);

            // Reset modelId and trainStatus when a new user logs in
            setModelId(null);
            setTrainStatus(null);

            // Fetch generated images and training status for the new user
            fetchGeneratedImages();
            fetchTrainingStatus(); // Fetch training status on login

            // Remove the redundant check
            // if (modelId && trainStatus === 'training') {
            //     pollTrainingStatus(modelId);
            // }
        } catch (error) {
            setAuthError(error.message);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userEmail');
        setToken(null);
        setUserEmail(null);
        setModelId(null);
        setTrainStatus(null);
        setUserModels([]);
        setGeneratedImages([]);
        setError(null);
        setShowAuth(true);
        setAnchorEl(null);
        setPrompt('');
        setUploadedFiles([]);
        setPreviewUrls([]);

        // Clear any ongoing polling
        if (pollingTimeoutRef.current) {
            clearTimeout(pollingTimeoutRef.current);
            pollingTimeoutRef.current = null;
        }
    };

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setOverlayOpen(true);
    };

    const handleOverlayClose = () => {
        setOverlayOpen(false);
        setSelectedImage(null);
    };

    const handleImageError = (id) => {
        //console.log(`Image ${id} failed to load.`);
        setImageLoadStates((prevState) => ({
            ...prevState,
            [id]: 'error',
        }));
    };

    useEffect(() => {
        const succeededModels = userModels.filter(model => model.status === 'succeeded');
        if (succeededModels.length === 1) {
            setSelectedModelId(succeededModels[0].modelId);
        } else {
            setSelectedModelId(null);
        }
    }, [userModels]);

    const handleAccountClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleUserInteraction = () => {
        setCreditError('');
        setTrainingCreditError('');
        setError(null);
        setTrainingSuccessMessage(false);
    };

    const handleRename = (modelId) => {
        setEditingModelId(modelId);
        setNewCustomName('name');
    };

    const saveCustomName = async (modelId) => {
        try {
            await fetch(`${process.env.REACT_APP_SERVER_URL}/models/${modelId}/custom-name`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ customName: newCustomName }),
            });

            setUserModels(prevModels => prevModels.map(model => 
                model.modelId === modelId ? { ...model, customName: newCustomName } : model
            ));
            setEditingModelId(null);
        } catch (error) {
            console.error('Error saving custom name:', error);
        }
    };

    const handleOutsideClick = (event) => {
        if (editingModelId && !event.target.closest('.edit-box')) {
            setEditingModelId(null);
        }
    };

    useEffect(() => {
        if (editingModelId) {
            document.addEventListener('click', handleOutsideClick);
        } else {
            document.removeEventListener('click', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [editingModelId]);

    const handleBuyCredits = () => {
        //console.log('Buy Credits clicked');
        setShowBuyCredits(true);
        handleMenuClose();
    };

    const handleCloseBuyCredits = () => {
        setShowBuyCredits(false);
    };

    const handleHelp = () => {
        setShowHelp(true);
    };

    const handleCloseHelp = () => {
        setShowHelp(false);
    };

    const handleChangePasswordClick = () => {
        setShowChangePassword(true);
        handleMenuClose(); // Close the account menu
    };

    const handleRewritePrompt = async () => {
        setLoadingRewrite(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/rewrite-prompt`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ prompt }),
            });

            if (!response.ok) {
                throw new Error('Failed to rewrite prompt');
            }

            const data = await response.json();
            setPrompt(data.improvedPrompt);
        } catch (error) {
            console.error('Error rewriting prompt:', error);
            setError('Failed to rewrite prompt');
        } finally {
            setLoadingRewrite(false);
        }
    };

    const handleImageLoad = (id) => {
        // Remove or comment out the console log
        // console.log(`Image ${id} loaded successfully.`);
        setImageLoadStates((prevState) => ({
            ...prevState,
            [id]: true,
        }));
    };

    const handleImagesMenuClick = (event) => {
        setImagesMenuAnchorEl(event.currentTarget);
    };

    const handleImagesMenuClose = () => {
        setImagesMenuAnchorEl(null);
    };

    const handleDeleteImagesClick = () => {
        setDeleteMode(true);
        setImagesMenuAnchorEl(null);
        setImagesToDelete([]);
    };

    const toggleSelectImage = (id) => {
        setImagesToDelete((prev) => {
            const newImagesToDelete = prev.includes(id)
                ? prev.filter((imageId) => imageId !== id)
                : [...prev, id];
            return newImagesToDelete;
        });
    };

    const handleConfirmDelete = async () => {
        try {
            // Remove images on the server
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/generated-images`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ imageIds: imagesToDelete }),
            });

            if (!response.ok) {
                throw new Error('Failed to delete images');
            }

            // Update state by removing deleted images
            const newGeneratedImages = generatedImages.filter(
                (image) => !imagesToDelete.includes(image._id)
            );
            setGeneratedImages(newGeneratedImages);
        } catch (error) {
            console.error('Error deleting images:', error);
            setError('Failed to delete images');
        } finally {
            setDeleteMode(false);
            setImagesToDelete([]);
        }
    };

    const handleCancelDelete = () => {
        setDeleteMode(false);
        setImagesToDelete([]);
    };

    const handleLoadMore = () => {
        setVisibleImagesCount(prevCount => prevCount + 50);
    };

    const handleClearPrompt = () => {
        setPrompt('');
    };

    const handleDownloadImage = () => {
        if (selectedImage) {
            // Open the image URL in a new tab
            window.open(selectedImage.url, '_blank');
        }
    };

    const handleShareImage = () => {
        if (selectedImage && navigator.share) {
            navigator.share({
                title: 'Check out this image!',
                text: selectedImage.prompt,
                url: selectedImage.url,
            }).then(() => {
                console.log('Image shared successfully');
            }).catch((error) => {
                console.error('Error sharing image:', error);
            });
        } else {
            alert('Web Share API is not supported in your browser.');
        }
    };

    // Detect if the device is mobile
    const isMobile = /Mobi|Android|iPhone|iPad/i.test(navigator.userAgent);

    // Define isTrainingInProgress as a derived variable
    const isTrainingInProgress = trainStatus === 'training';

    // Filter out failed models
    const availableModels = userModels.filter(model => model.status !== 'failed');

    // Add the handleCopyPrompt function
    const handleCopyPrompt = () => {
        if (selectedImage && selectedImage.prompt) {
            navigator.clipboard.writeText(selectedImage.prompt)
                .then(() => {
                    // Optional: Show a success message or feedback
                    console.log('Prompt copied to clipboard');
                })
                .catch((err) => {
                    console.error('Failed to copy prompt: ', err);
                });
        }
    };

    const checkIfNewUser = () => {
        if (!dataFetched) {
            // Data is not yet loaded
            return false;
        }

        const hasNoGeneratedImages = generatedImages.length === 0;
        const hasNoModels = userModels.length === 0;

        return hasNoGeneratedImages && hasNoModels;
    };

    useEffect(() => {
        const newUser = checkIfNewUser();
        setIsNewUser(newUser);
    }, [generatedImages, userModels, dataFetched]);

    const handleTrainingSuccess = () => {
        updateTrainStatus('completed');
        setLoading(false);
        setTrainingProgress('Training completed successfully! You can now generate images.');
        setTrainingSuccessMessage(true); // Show success message
        setUploadedFiles([]); // Clear selected images
        setPreviewUrls([]); // Clear preview URLs
        console.log('Training completed successfully!'); // Log success

        if (Notification.permission === 'granted') {
            new Notification('Training Complete', {
                body: 'Your model training has completed successfully!',
            });
        }

        setError(null);

        // Update training status on the server
        console.log(`Sending request to update training status for modelId: ${modelId} to 'succeeded'`);
        fetch(`${process.env.REACT_APP_SERVER_URL}/update-training-status`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ modelId, status: 'succeeded' }),
        });

        // Fetch updated models list
        fetchUserModels();

        // Clear the polling timeout
        if (pollingTimeoutRef.current) {
            clearTimeout(pollingTimeoutRef.current);
            pollingTimeoutRef.current = null;
        }
    };

    const handleTrainingFailure = () => {
        updateTrainStatus('failed');
        setLoading(false);
        setError("Sorry - our GPU's are glitchy today. Please retry");
        setTrainingProgress('Training failed. Please try again.');
        console.log('Training failed'); // Log failure

        // Update training status on the server
        console.log(`Sending request to update training status for modelId: ${modelId} to 'failed'`);
        fetch(`${process.env.REACT_APP_SERVER_URL}/update-training-status`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ modelId, status: 'failed' }),
        });

        // Fetch updated models list
        fetchUserModels();

        // Clear the polling timeout
        if (pollingTimeoutRef.current) {
            clearTimeout(pollingTimeoutRef.current);
            pollingTimeoutRef.current = null;
        }
    };

    return (
        <Container maxWidth="md" onClick={handleUserInteraction}>
            {/* Header */}
            <Box sx={{ my: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* Logo */}
                        <img
                            src={`${process.env.PUBLIC_URL}/greatshotslogo.png`}
                            alt="GreatShots Logo"
                            style={{ maxWidth: '50px', height: 'auto', marginRight: '10px' }}
                        />
                        {/* Title Logo */}
                        <img
                            src={`${process.env.PUBLIC_URL}/greatshotstextlogo.png`}
                            alt="New Logo"
                            style={{ maxWidth: '150px', height: 'auto' }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* Help button accessible to all users */}
                        <Button
                            variant="text"
                            color="inherit"
                            onClick={handleHelp}
                        >
                            Help
                        </Button>
                        {token && (
                            <>
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    onClick={handleAccountClick}
                                >
                                    <AccountCircle sx={{ fontSize: '2.5rem' }} />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem disabled>{userEmail}</MenuItem>
                                    <Divider />
                                    <MenuItem disabled sx={{ padding: '4px 16px', minHeight: '32px'  }}>
                                        Model Credits: {modelTrainingCredits}
                                    </MenuItem>
                                    <MenuItem disabled sx={{ padding: '4px 16px', minHeight: '32px' }}>
                                        Image Credits: {imageGenerationCredits}
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem onClick={handleBuyCredits} sx={{ padding: '4px 16px', minHeight: '32px' }}>
                                        Buy Credits
                                    </MenuItem>
                                    <MenuItem onClick={handleChangePasswordClick} sx={{ padding: '4px 16px', minHeight: '32px' }}>
                                        Change Password
                                    </MenuItem>
                                    <MenuItem onClick={handleLogout} sx={{ padding: '4px 16px', minHeight: '32px' }}>
                                        Logout
                                    </MenuItem>
                                </Menu>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>

            {showHelp ? (
                <Help onClose={handleCloseHelp} />
            ) : showBuyCredits ? (
                <BuyCredits onClose={handleCloseBuyCredits} />
            ) : token ? (
                <>
                    {/* Main App Content */}
                    {token && (
                        <>
                            {/* Generated Images Section */}
                            {generatedImages.length > 0 && (
                                <Paper elevation={3} sx={{ p: 3, mb: 3, position: 'relative' }}>
                                    {deleteMode ? (
                                        <>
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                                                <Button variant="contained" color="primary" onClick={handleConfirmDelete}>
                                                    Delete
                                                </Button>
                                                <Button variant="outlined" color="primary" onClick={handleCancelDelete} sx={{ ml: 2 }}>
                                                    Cancel
                                                </Button>
                                            </Box>
                                        </>
                                    ) : (
                                        <>
                                            <Typography variant="h6" gutterBottom>
                                                Your Generated Images
                                            </Typography>
                                            <IconButton
                                                onClick={handleImagesMenuClick}
                                                sx={{ position: 'absolute', top: 8, right: 8 }}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                anchorEl={imagesMenuAnchorEl}
                                                open={Boolean(imagesMenuAnchorEl)}
                                                onClose={handleImagesMenuClose}
                                            >
                                                <MenuItem onClick={handleDeleteImagesClick}>Delete Images</MenuItem>
                                            </Menu>
                                        </>
                                    )}
                                    {!deleteMode && (
                                        <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                                            Click to see full-size image
                                        </Typography>
                                    )}
                                    <ImageList cols={getImageListCols()} gap={8}>
                                        {generatedImages
                                            .slice()
                                            .reverse()
                                            .slice(0, visibleImagesCount)
                                            .filter(
                                                (image) => !deleteMode || !imagesToDelete.includes(image._id)
                                            )
                                            .map((image) => {
                                                const loadState = imageLoadStates[image._id];

                                                const handleClick = (e) => {
                                                    e.stopPropagation(); // Prevent event bubbling
                                                    if (deleteMode) {
                                                        // Toggle selection for deletion
                                                        toggleSelectImage(image._id);
                                                    } else {
                                                        // Open image overlay
                                                        handleImageClick(image);
                                                    }
                                                };

                                                return (
                                                    <ImageListItem
                                                        key={image._id}
                                                        style={{
                                                            width: '150px',
                                                            height: '150px',
                                                            position: 'relative',
                                                        }}
                                                        onClick={handleClick}
                                                    >
                                                        <img
                                                            src={image.url}
                                                            alt="Generated"
                                                            onLoad={() => handleImageLoad(image._id)}
                                                            onError={() => handleImageError(image._id)}
                                                            style={{
                                                                width: '100%',
                                                                height: '100%',
                                                                objectFit: 'cover',
                                                                display: loadState === 'error' ? 'none' : 'block',
                                                                opacity: imagesToDelete.includes(image._id) ? 0.5 : 1,
                                                                cursor: deleteMode ? 'pointer' : 'default',
                                                            }}
                                                        />
                                                        {!loadState && (
                                                            <CircularProgress
                                                                size={48}
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform: 'translate(-50%, -50%)',
                                                                }}
                                                            />
                                                        )}
                                                        {loadState === 'error' && (
                                                            <Box
                                                                sx={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    backgroundColor: '#f0f0f0',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                <BrokenImageIcon
                                                                    sx={{ fontSize: 48, color: '#ccc' }}
                                                                />
                                                            </Box>
                                                        )}
                                                        {deleteMode && (
                                                            <IconButton
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    toggleSelectImage(image._id);
                                                                }}
                                                                sx={{
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    right: 0,
                                                                    color: 'white',
                                                                    backgroundColor: imagesToDelete.includes(image._id)
                                                                        ? 'rgba(255, 0, 0, 0.7)'
                                                                        : 'rgba(0, 0, 0, 0.5)',
                                                                }}
                                                            >
                                                                <CloseIcon />
                                                            </IconButton>
                                                        )}
                                                    </ImageListItem>
                                                );
                                            })}
                                    </ImageList>
                                    {generatedImages.length > visibleImagesCount && (
                                        <Button onClick={handleLoadMore} sx={{ mt: 2 }}>
                                            See more...
                                        </Button>
                                    )}
                                </Paper>
                            )}

                            {/* Generate Images Section */}
                            {userModels.length > 0 && (
                                <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                    <Typography variant="h6" gutterBottom>
                                        Generate Images
                                    </Typography>
                                    {creditError && (
                                        <Alert severity="error" sx={{ mb: 2 }}>{creditError}</Alert>
                                    )}
                                    <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                                        Include USER in the prompt, e.g., USER as an astronaut.&nbsp;
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            color="primary"
                                            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                                            onClick={handleSamplePromptsOpen}
                                        >
                                            Sample Prompts
                                        </Typography>
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        label="Enter prompt"
                                        value={prompt}
                                        onChange={(e) => setPrompt(e.target.value)}
                                        margin="normal"
                                        multiline
                                        minRows={3}
                                        maxRows={10}
                                        inputProps={{
                                            style: {
                                                maxHeight: 200,
                                                overflow: 'auto',
                                            },
                                        }}
                                    />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography
                                            variant="body2"
                                            color="primary"
                                            sx={{ mt: 0.5, cursor: 'pointer', textDecoration: 'underline', fontSize: '0.8rem' }}
                                            onClick={handleClearPrompt}
                                        >
                                            Clear
                                        </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexGrow: 1 }}>
                                            <Box sx={{ width: 20, mr: 1 }}>
                                                {loadingRewrite && <CircularProgress size={16} />}
                                            </Box>
                                            <Typography
                                                variant="body2"
                                                color="primary"
                                                sx={{ mt: 0.5, cursor: 'pointer', textDecoration: 'underline', fontSize: '0.8rem' }}
                                                onClick={handleRewritePrompt}
                                            >
                                                Rewrite with ChatGPT
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {prompt && !selectedModelId && (
                                        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                            Please select a model
                                        </Typography>
                                    )}
                                    <Button
                                        variant="contained"
                                        onClick={handleGenerate}
                                        disabled={imageLoading || !prompt || !selectedModelId}
                                        fullWidth
                                        sx={{ mt: 2 }}
                                    >
                                        {imageLoading ? <CircularProgress size={24} /> : 'Generate Image'}
                                    </Button>
                                </Paper>
                            )}

                            {/* Models List */}
                            {availableModels.length > 0 && (
                                <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                                    <Typography variant="h6" gutterBottom>
                                        Your Models
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                                        Select a model to generate images
                                    </Typography>
                                    <ul>
                                        {availableModels.map((model, index) => (
                                            <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                                <Button
                                                    variant={selectedModelId === model.modelId ? 'contained' : 'outlined'}
                                                    onClick={() => setSelectedModelId(model.modelId)}
                                                    disabled={model.status !== 'succeeded'}
                                                    sx={{ marginRight: '8px', width: '300px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                                                >
                                                    {model.customName || 'FLUXMODEL'}
                                                </Button>
                                                {editingModelId === model.modelId ? (
                                                    <div className="edit-box" style={{ display: 'flex', alignItems: 'center' }} onClick={(e) => e.stopPropagation()}>
                                                        <TextField
                                                            value={newCustomName}
                                                            onChange={(e) => setNewCustomName(e.target.value)}
                                                            size="small"
                                                            sx={{ marginRight: '8px' }}
                                                            autoFocus
                                                        />
                                                        <Button onClick={() => saveCustomName(model.modelId)} size="small">
                                                            Save
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <Button onClick={(e) => { e.stopPropagation(); handleRename(model.modelId); }} size="small">
                                                        Rename
                                                    </Button>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </Paper>
                            )}

                            {/* Show GetStartedOverlay only if data is fetched and user is new */}
                            {dataFetched && isNewUser && (
                                <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                                    <GetStartedOverlay setShowBuyCredits={setShowBuyCredits} />
                                </Paper>
                            )}
                            
                            {/* Train Model Section */}
                            <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                                <Typography variant="h6" gutterBottom>
                                    Train a Model
                                </Typography>
                                <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                                    Train a model with high quality images of the subject from different directions. 7-12 images will work well. The training process will take about 20 minutes. 
                                   <br/> <em><b>Note:</b> if many of your images are blurry or distorted the model may learn that this is the desired output.</em>
                                </Typography>

                                {trainingSuccessMessage && (
                                    <Alert severity="success" sx={{ mb: 2 }}>
                                        Model training was successful
                                    </Alert>
                                )}

                                <input
                                    accept="image/*"
                                    type="file"
                                    onChange={handleFileSelect}
                                    style={{ display: 'none' }}
                                    id="upload-button"
                                    multiple
                                />
                                <label htmlFor="upload-button">
                                    <Button variant="contained" component="span" fullWidth>
                                        Select Photos ({uploadedFiles.length}/20)
                                    </Button>
                                </label>

                                {previewUrls.length > 0 && (
                                    <Box sx={{ mt: 2 }}>
                                        <ImageList cols={3} rowHeight={164}>
                                            {previewUrls.map((url, index) => (
                                                <ImageListItem key={index}>
                                                    <img src={url} alt={`Upload ${index + 1}`} />
                                                    <IconButton
                                                        sx={{ position: 'absolute', right: 0, top: 0 }}
                                                        onClick={() => removeImage(index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </Box>
                                )}

                                {error && (
                                    <Alert severity="error" sx={{ mb: 2 }}>
                                        {error}
                                    </Alert>
                                )}

                                {trainingCreditError && (
                                    <Alert severity="error" sx={{ mb: 2 }}>
                                        {trainingCreditError}
                                    </Alert>
                                )}

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => {
                                        e.stopPropagation();  // Prevent the event from bubbling up
                                        handleTrain();
                                    }}
                                    disabled={loading || uploadedFiles.length === 0 || isTrainingInProgress}
                                    fullWidth
                                    sx={{ mt: 2 }}
                                >
                                    {loading ? <CircularProgress size={24} /> : 'START TRAINING'}
                                </Button>
                            </Paper>

                            {/* Training Status */}
                            {isTrainingInProgress && (
                                <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                                    <Typography variant="h6" gutterBottom>
                                        Training Status
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <CircularProgress size={24} />
                                        <Typography>
                                            {trainingProgress || 'Initializing training...'}
                                        </Typography>
                                    </Box>
                                    <LinearProgress 
                                        variant="determinate" 
                                        value={progress} 
                                        sx={{ 
                                            mt: 2,
                                            height: 8,
                                            borderRadius: 4,
                                            '& .MuiLinearProgress-bar': {
                                                borderRadius: 4,
                                            },
                                            '& .MuiLinearProgress-dashed': {
                                                display: 'none',
                                            }
                                        }} 
                                    />
                                    <Typography variant="body2" sx={{ mt: 2 }}>
                                        {latestLogLine}
                                    </Typography>
                                </Paper>
                            )}

                            {/* Image Overlay Dialog */}
                            <Dialog 
                                open={overlayOpen} 
                                onClose={handleOverlayClose} 
                                maxWidth="sm"
                            >
                                <DialogContent sx={{ textAlign: 'center' }}>
                                    {selectedImage && (
                                        <>
                                            <img 
                                                id="full-size-image"
                                                src={selectedImage.url} 
                                                alt="Full-size" 
                                                style={{ 
                                                    maxWidth: '100%', 
                                                    maxHeight: '60vh', 
                                                    cursor: 'pointer' 
                                                }} 
                                                onClick={handleOverlayClose}
                                            />
                                            <Box 
                                                sx={{ 
                                                    mt: 2, 
                                                    maxHeight: '150px', 
                                                    overflow: 'auto', 
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <Typography variant="body2">
                                                    {selectedImage.prompt}
                                                </Typography>
                                            </Box>
                                        </>
                                    )}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCopyPrompt} color="primary">
                                        Copy Prompt
                                    </Button>
                                    <Button onClick={handleDownloadImage} color="primary">
                                        Download
                                    </Button>
                                    <Button onClick={handleOverlayClose} color="primary">
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            {/* Sample Prompts Dialog */}
                            <Dialog open={samplePromptsOpen} onClose={handleSamplePromptsClose}>
                                <DialogTitle>Select a Sample Prompt</DialogTitle>
                                <DialogContent>
                                    <List>
                                        {samplePrompts.map((sample, index) => (
                                            <ListItemButton key={index} onClick={() => handleSelectSamplePrompt(sample)}>
                                                <ListItemText primary={sample} />
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleSamplePromptsClose}>Close</Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    )}
                </>
            ) : (
                <>
                    {/* Show SplashPage when user is not logged in */}
                    <SplashPage
                        onLoginClick={() => { setIsLogin(true); setShowAuth(true); }}
                        onTryNowClick={() => { setIsLogin(false); setShowAuth(true); }}
                    />

                    {/* Auth Dialog */}
                    {showAuth && (
                        <Dialog
                            open={showAuth}
                            onClose={() => setShowAuth(false)}
                            maxWidth="xs"
                            fullWidth
                        >
                            <DialogTitle>
                                {isLogin ? 'Login' : 'Sign Up'}
                            </DialogTitle>
                            <form onSubmit={handleAuth}>
                                <DialogContent>
                                    {authError && (
                                        <Alert severity="error" sx={{ mb: 2 }}>
                                            {authError}
                                        </Alert>
                                    )}
                                    <TextField
                                        fullWidth
                                        label="Email"
                                        type="email"
                                        value={authEmail}
                                        onChange={(e) => setAuthEmail(e.target.value)}
                                        margin="normal"
                                        required
                                        autoComplete="email"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Password"
                                        type="password"
                                        value={authPassword}
                                        onChange={(e) => setAuthPassword(e.target.value)}
                                        margin="normal"
                                        required
                                        autoComplete="current-password"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    {!isLogin && (
                                        <Typography variant="body2" sx={{ mt: 2 }}>
                                            By signing up, you agree to our <a href="/terms-of-service.html" target="_blank" rel="noopener noreferrer">Terms of Service</a>.
                                        </Typography>
                                    )}
                                </DialogContent>
                                <DialogActions sx={{ px: 3, pb: 2 }}>
                                    <Button onClick={() => setIsLogin(!isLogin)}>
                                        {isLogin ? 'Need an account? Sign Up' : 'Have an account? Login'}
                                    </Button>
                                    <Button type="submit" variant="contained">
                                        {isLogin ? 'Login' : 'Sign Up'}
                                    </Button>
                                </DialogActions>
                            </form>
                        </Dialog>
                    )}
                </>
            )}

            {showChangePassword && (
                <ChangePassword
                    onClose={() => setShowChangePassword(false)}
                />
            )}

            {/* Footer */}
            <Box sx={{ mt: 4, textAlign: 'center', pb: 4 }}>
                <Typography variant="body2">
                    <a href="/terms-of-service.html" target="_blank" rel="noopener noreferrer">Terms of Service</a> | 
                    <a href="/privacy-policy.html" target="_blank" rel="noopener noreferrer" style={{ marginLeft: '8px' }}>Privacy Policy</a>
                </Typography>
            </Box>

            {/* Remove or comment out this block */}
            {/* {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )} */}


        </Container>
    );
}

const theme = createTheme();

function AppWrapper() {
    return (
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    );
}

export default AppWrapper;